<template>
  <router-link :to="{ name: 'PlayerHome', params: { lang: lang, console: console, playerSlug: player.slug } }">
    <div class="player-card mb-3">
      <img :src="player.card" :alt="`Tarjeta ${player.nick}`" class="img-card">
      <div class="player-card-left">
        <span class="overall border-text-black">{{ player.overall }}</span>
        <img v-if="player.team_name" :src="player.custom_image_team" :alt="player.team_name" :title="player.team_name" class="img-team">
        <img :src="player.country_flag" :alt="`Pais de ${player.nick}`" class="img-country">
      </div>
      <div class="player-card-right">
        <img :src="player.avatar" :alt="`Avatar de ${player.nick}`" class="img-avatar">
      </div>
      <h2 class="player-card-user border-text-black">{{ player.nick }}</h2>
      <span class="position border-text-black">{{ player.position | completePosition }}</span>
    </div>
  </router-link>
</template>
<script>
export default {
  props: {
    player: {
      required: true,
      type: Object
    },
    lang: {
      required: true,
      type: String
    },
    console: {
      required: true,
      type: String
    }
  }
}
</script>
